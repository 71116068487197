import React, { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
// import { SlArrowRightCircle } from "react-icons/sl";
// import { SlArrowRight } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import styled from "styled-components";
import "./Products.css";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";

import colors from "./../../resources/styles/colors";
import loaders from "./../../utils/personas_loaders";

import config from "../../config";

// Define the ArrowButton using styled-components
const ArrowButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2px;
  opacity: ${props => (props.disabled ? "0" : "1")};
  user-select: none;
  border-radius: 6px;
  border-width: 1px;
  background: "transparent";
`;

// Arrow function component
function Arrow({ children, disabled, onClick, className, testId }) {
  return (
    <ArrowButton
      disabled={disabled}
      onClick={onClick}
      className={`arrow-${className}`}
      data-testid={testId}
    >
      {children}
    </ArrowButton>
  );
}

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function App(props) {
  // console.log("props 1", props);
  const [items, setItems] = React.useState(props.items || []);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);
  let getProducts = props.getProducts;
  let mainItem = props.item;

  useEffect(
    () => {
      setItems(props.items);
    },
    [props.items]
  ); // Re-run the effect only if props.items changes

  const isItemSelected = id => !!selected.find(el => el === id);

  const handleClick = id => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id);

    setSelected(
      currentSelected =>
        itemSelected
          ? currentSelected.filter(el => el !== id)
          : currentSelected.concat(id)
    );
  };

  const apiRef = React.useRef({});

  const myScrollNext = () => {
    console.log(apiRef.current);
    console.log(apiRef.current.getNextItem());
    let nextItem = apiRef.current.getNextItem();
    let totalItems = items.length;
    console.log(totalItems);
    if (nextItem) {
      let id = nextItem.key;
      let tmpId = parseInt(id) + 2;
      let newId = tmpId < totalItems - 1 ? tmpId : totalItems - 1;
      console.log("newId", newId);
      apiRef.current.scrollToItem(
        apiRef.current.getItemById(`${newId}`)
        // OR if you not sure about id for first item
        // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
        // "auto",
        // "start"
      );
    }
  };

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(
      VisibilityContext
    );

    return (
      <div style={{ width: 0, height: 0 }} onClick={() => scrollPrev()}>
        <div
          style={{
            position: "relative",
            left: 10,
            top: 17,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isFirstItemVisible && <div />}
          {!isFirstItemVisible && (
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowBack size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(
      VisibilityContext
    );

    return (
      <div style={{ width: 0, height: 0 }} onClick={() => myScrollNext()}>
        <div
          style={{
            position: "relative",
            left: -40,
            top: 17,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isLastItemVisible && <div />}
          {!isLastItemVisible && (
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  // flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowForward size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }

  function Card({
    onClick,
    selected,
    title,
    itemId,
    item,
    keyNr,
    getProducts,
    mainItem
  }) {
    const visibility = React.useContext(VisibilityContext);
    let suggestedProduct = item;

    return (
      <View
        mt={16}
        mb={8}
        mr={4}
        ml={keyNr == 0 ? 58 : 4}
        onClick={() => onClick(visibility)}
        style={{
          minWidth: "20px", // Minimum width
          whiteSpace: "nowrap", // Keep text in a single line
          overflow: "hidden" // Hide overflowed text
        }}
        tabIndex={0}
      >
        <div className="card">
          <View style={{}} key={itemId}>
            <TouchableOpacity
              onClick={() => {
                getProducts(mainItem._id, suggestedProduct);
              }}
            >
              <View
                // mr={10}
                // my={10}
                // mx={10}
                // px={8}
                // py={16}
                style={{
                  // height: 40,
                  border: `1px solid ${suggestedProduct.id ==
                  mainItem.activeSuggestedProduct
                    ? "#000"
                    : colors.inkLight}`,
                  borderRadius: 4

                  // backgroundColor:
                  //   suggestedProduct.id == mainItem.activeSuggestedProduct
                  //     ? colors.bdLine
                  //     : "#fff"
                }}
              >
                <Text
                  tiny_none_regular
                  px={12}
                  py={8}
                  style={{
                    color:
                      suggestedProduct.id == mainItem.activeSuggestedProduct
                        ? colors.inkBase
                        : colors.inkLight
                  }}
                >
                  {suggestedProduct.title}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </div>
      </View>
    );
  }

  return (
    <ScrollMenu
      apiRef={apiRef}
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      // style={{ paddingLeft: "20px", paddingRight: "20px" }}
    >
      {items.map((item, keyNr) => (
        <Card
          itemId={keyNr} // NOTE: itemId is required for track items
          title={item.id}
          key={item.id}
          onClick={handleClick(item.id)}
          selected={isItemSelected(item.id)}
          item={item}
          keyNr={keyNr}
          getProducts={getProducts}
          mainItem={mainItem}
        />
      ))}
    </ScrollMenu>
  );
}

// <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//   Left
// </Arrow>

export default App;
