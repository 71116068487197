import React, { Component } from "react";
import styled from "styled-components";

import config from "../../config";
import colors from "./../../resources/styles/colors";

// Assuming you have these styled components defined
import { View, Text, Image } from "./../../components/styled/styledComponents";

// import logoImg from "./../../assets/logo_runa_780x780.png";
// import logoImg2 from "./../../assets/logo_runa_550x550.png";

function isMobileDevice() {
  return window.innerWidth <= 768; // You can adjust the width as per your requirements
}

class FloatingChatButton extends React.PureComponent {
  // Default position based on device type
  getDefaultPosition() {
    return isMobileDevice() ? "center" : "right";
  }
  render() {
    // Get the position prop or use the default based on the device type
    const position = this.props.position || this.getDefaultPosition();

    console.log(this.props.userPersona)

    // Styled components for web
    const StyledTouchableOpacity = styled.button`
      background-color: ${this.props.userPersona
        ? this.props.userPersona.chat.primaryColor
        : colors.bgVioletBase};
      border-radius: 30px;
      padding: 16px;
      border: none;
      cursor: pointer;
    `;

    const StyledContainer = styled.div`
      position: fixed;
      bottom: 20px;
      ${props => {
        switch (props.position) {
          case "left":
            return "left: 20px;";
          case "center":
            return "left: 50%; transform: translateX(-50%);";
          case "right":
            return "right: 20px;";
          default:
            return "right: 50px;";
        }
      }};
    `;

    return (
      <View>
        {this.props.userPersona && (
          <StyledContainer position={position}>
            <StyledTouchableOpacity
              onClick={this.props.onPress}
              style={{
                //height: 52
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Image
                  source={{
                    uri:
                      "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_550x550.png"
                  }}
                  style={{
                    width: 18,
                    height: 18
                    // border: "1px solid blue"
                    // borderRadius: 50,

                    // borderColor: colors.bdLine,
                    // borderWidth: 1
                  }}
                  resizeMode="contain"
                />
                <Text ml={8} mr={8} regular_none_bold style={{ color: "#fff" }}>
                  {this.props.userPersona.chat.name}
                </Text>
              </div>
            </StyledTouchableOpacity>
          </StyledContainer>
        )}
      </View>
    );
  }
}

export default FloatingChatButton;
