// TODO store config in the root of the project as config.json and add it to webpack
let config = {
  // apolloLink: 'http://192.241.136.153:4000/',
  // apiRoot: 'http://192.241.136.153',
  // userId: 'e67e9bf4-83da-4077-9313-539927a20517', // 'cjdod5tvs09e40102lub33la9',   // remote

  userId: "default",

  appId: null, // 'app-guide',
  appPlaceId: null, // 'app-guide', // 'app-guide-ChIJD7fiBh9u5kcRYJSMaMOCCwQ',
  appName: "",
  isBusinessApp: false,
  isBusinessPremium: false,
  isBusinessLuxury: false,
  isBusinessLifestyle: false,

  // appId: 'app-luxury-lifestyle', // 'app-guide',
  // appPlaceId: 'app-luxury-lifestyle', // 'app-guide', // 'app-guide-ChIJD7fiBh9u5kcRYJSMaMOCCwQ',
  // appName: 'Luxury Lifestyle',
  // isBusinessApp: true,
  // isBusinessPremium: true,
  // isBusinessLuxury: true,

  // userId: 'default', //'8398f2fe-deb9-4c67-9b35-11903cabff7c', // null,
  // userChatEngine: null,
  // userFullName: null,
  // userImage: null,
  // apolloLink: 'http://192.168.87.20:4000',
  // apiRoot: 'http://192.168.87.20',
  // userId: 'cjdod968s09fkf_renderItem0102szmzc2a9',   //local

  // apolloLink: 'http://192.168.1.123:4000',
  // apiRoot: 'http://192.168.1.123',
  // userId: 'cjdod968s09fk0102szmzc2a9',   //local

  // apolloLink: 'http://localhost:4000/',
  // apiRoot: 'http://localhost',

  app: "default",
  // app: 'location',

  //  recentSearches: ['London', 'Paris'],
  recentSearches: [
    // // {
    // //   description: 'France',
    // //   geometry: { location: { lat: 46.227638, lng: 2.213749 } },
    // // },
    //
    // {
    //   description: 'Peru',
    //   geometry: { location: { lat: -9.189966999999998, lng: -75.015152 } },
    // },
    // {
    //   description: 'Singapore',
    //   geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
    // },
    // // {
    // //   description: 'Paris, France',
    // //   geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
    // // },
    // // {
    // //   description: 'London, UK',
    // //   geometry: { location: { lat: 48.8496818, lng: 2.2940881 } },
    // // },
    //
    // // {
    // //   description: 'Tokyo, Japan',
    // //   geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
    // // },
    // // {
    // //   description: 'Barcelona, Spain',
    // //   geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
    // // },
    // {
    //   description: 'Bangkok, Thailand',
    //   geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
    // },
  ],
  markers: [],
  currentTimeline: {},
  timelineId: null,
  currentLocation: null,
  currentLocationLatLong: null,
  currentLocationCategoryId: null,
  currentLocationCategoryName: null,
  currentLocationCategories: [],
  facebookAdsPlacementIdIos: "",
  facebookAdsPlacementIdAndroid: "",
  facebookScheme: "fb432569525309613",
  facebookAppId: "432569525309613",
  facebookDisplayName: "MOD",
  adUnitIDIos: "ca-app-pub-5086722392564462/4174183307",
  adUnitIDAndroid: "ca-app-pub-5086722392564462/1767511817",
  // stripePublicKey: 'pk_live_imZ6IqTBoAZDetErVvQGydWn', // public // 'pk_test_seSrDfsBaOTSigY1NbgUd1fy',
  stripePublicKey: "pk_test_seSrDfsBaOTSigY1NbgUd1fy", // test // 'pk_test_seSrDfsBaOTSigY1NbgUd1fy',
  // stripeClientId: 'ca_G5SrJk81fVe8jRxH8pIAEUSugr7WOB8D', // public
  stripeClientId: "ca_G5SrffX8waMHq6KiXD5W5Y96YUjMhlvX", // test
  paymentApiUrl:
    "https://1ajuudzgv8.execute-api.us-east-1.amazonaws.com/production/test",
  checkoutUrl: "https://www.toogl.io/checkout/",

  stripeTestPublicKey: "pk_test_seSrDfsBaOTSigY1NbgUd1fy", // 'pk_test_seSrDfsBaOTSigY1NbgUd1fy',

  isPremium: false,
  // apiRoot: 'http://localhost:8080',
  // apiRoot: 'http://api.chronolineapp.com/api',
  // publicRoot: 'http://localhost:3000',
  // publicRoot: 'http://api.chronolineapp.com',

  // apolloLink: 'http://localhost:4000',
  // apiRoot: 'http://localhost',

  STREAM_API_KEY: "pnxcj8cy4nta",
  STREAM_APP_ID: "42809",
  STREAM_API_TOKEN: "",
  isBusiness: false,
  availableCountry: "ro",
  gadventuresId: "63e0831e-8112-4094-8bcc-a708bf927307",
  testTravelAgencyId: "2b5c7abd-de22-4160-b31a-3e13afc0b183",
  localVersion: "",
  isVacation: false,
  privacyPolicyUrl: "https://www.modapp.me/privacy-policy",
  licenseAgreementUrl: "https://www.modapp.me/terms-and-conditions",
  partnersUrl: "https://www.toogl.io/web/business.html",
  uploadUrl: "https://d2yqk81ceke6jr.cloudfront.net",
  proxyUrl:
    "https://1ajuudzgv8.execute-api.us-east-1.amazonaws.com/production/proxy?url=",

  // pubnubPublishKey: 'pub-c-01859869-9d2c-452b-a9b5-2c3a6a39243d',
  // pubnubSubscribeKey: 'sub-c-4681fe82-9f2c-11e9-a0e6-3684ccde0898',
  pubnubPublishKey: "pub-c-01859869-9d2c-452b-a9b5-2c3a6a39243d",
  pubnubSubscribeKey: "sub-c-4681fe82-9f2c-11e9-a0e6-3684ccde0898",

  zoomToken:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6ImRaQnRrR3hYVEY2dmk1YjFqZjE4dnciLCJleHAiOjE3NTE0NjQ5MjAsImlhdCI6MTU5MzY5MzE3OH0.naJozC2aaJnuCGuMEzGQpEoxBS2r6uFc85dT10kkwfU",
  proxyDefaultUrl:
    "https://1ajuudzgv8.execute-api.us-east-1.amazonaws.com/production/proxy",
  googleMapsApiKey: "AIzaSyCZceC2MdSYZyce0gn75pGG9miWvC_oE8k",
  sendgrid:
    "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",
  updatesChannelId: "tOOgl_app_updates",

  // downloadAppStoreLink:
  //   'https://itunes.apple.com/app/traveland/id1416501523?mt=8',
  // downloadGooglePlayLink:
  //   'https://play.google.com/store/apps/details?id=com.chronoline.travelia',

  defaultChromaticImg: "icon-1-cool-summer.png",
  defaultSiluetaImg: "Icon-75.png",
  defaultPersonalityImg: "Romantica-42.png",
  personalityImages: {
    clasica: "clasica_app_transparent_2.png",
    romantica: "romantica_app_transparent_2.png",
    dramatica: "dramatica_app_transparent_2.png",
    naturala: "naturala_app_transparent_2.png",
    creativa: "creativa_app_transparent_2.png"
  },
  siluetteImages: {
    oval: "oval_grey.png",
    triunghi: "triunghi_grey.png",
    "triunghi-intors": "triunghi-intors_grey.png",
    dreptunghi: "dreptunghi_grey.png",
    clepsidra: "clepsidra_grey.png"
  },
  siluetteName: "",
  siluetteHeightName: "",
  personalityName: "",
  personalityCode1: "",
  personalityCode2: "",
  siluetteCode: "",
  chromaticCode: "",
  paletteName: "",
  facebookAppId: "432569525309613",
  colorMatch1: 4,
  colorMatch2: 7,
  colorMatch3: 11,
  imgUri: "",
  maxColor: 10,
  minScore: 0.35,
  noMatchScore: 0.27, // 0.25,
  mixpanelKey: "7bc7ec1fd20f92b52d774328619be4c6",
  toplyneKey: "4f713cc4df5d46e6965d72b1de6583d2",
  typeform: {
    // en: {
    //   siluetteId: 'kcaOf7p0',
    //   personalityId: 'heJuT31C',
    //   chromaticId: 'MpZtXZzT',
    // },
    // ro: {
    //   siluetteId: 'rPEyByRM',
    //   personalityId: 'dI5VRJOK',
    //   chromaticId: 'UoxqYjqp',
    // },

    // en: {
    //   siluetteId: 'oPpTfNAD',
    //   personalityId: 'FZln5UC4',
    //   chromaticId: 'HpBorphu',
    // },
    // ro: {
    //   siluetteId: 'saLUOYqv',
    //   personalityId: 'rxdvpnss',
    //   chromaticId: 'dYSP7BA0',
    // },

    en: {
      siluetteId: "SYqnRqyF", // "oPpTfNAD", // "SYqnRqyF", // 'oPpTfNAD',
      personalityId: "r9R5tFjT", // "FZln5UC4",
      chromaticId: "Mx4UYhMQ" // "HpBorphu"
    },
    ro: {
      siluetteId: "saLUOYqv",
      personalityId: "rxdvpnss",
      chromaticId: "dYSP7BA0"
    }
  },
  locale: "en",
  isLocalUser: false,
  localUsers: [
    "0c822213-a9e7-4ef4-ac76-76b865284ed0",
    "87956e83-3fab-41a7-9409-9c5b6facfc35",
    "8e4cc9ea-552b-4597-9ec9-5f061a3c2475",
    // "0e4d5255-1bfe-4443-b050-9c41178da99f",
    "0931c719-f2eb-4334-b98a-013fbf167861",
    "bf9f2c3f-cf3d-4ff2-9757-06d2c947fa54",
    "f804da7e-5af9-4969-8149-29d806f69e2e",
    "f46f47e4-0e1d-458e-91f8-683b22e4bd64"
  ],
  blurhash_2x3: "J1QAEk-;_NfRfQfQ",
  analytics: { trackProduction: () => {} },
  // "https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/mod-app"
  appServerUrl:
    "https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/healthiny-app",
  appId: "runa",
  testSite: "demo.runachat.ai"
};

// let urlSiluette = `https://form.typeform.com/to/${config.typeform[config.locale].siluetteId}#user_id=${userId}`;
// let urlPersonality = `https://form.typeform.com/to/${config.typeform[config.locale].personalityId}#user_id=${userId}`;
// let urlChromatic = `https://form.typeform.com/to/${config.typeform[config.locale].chromaticId}#user_id=${userId}`;

export default config;
