import React, { Component } from "react";
import Lottie from "lottie-react";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";
import FloatingChatButton from "./FloatingChatButton";
import ChatWindow from "./ChatWindow";
import loadingActivityIndicator from "./../../assets/loading_activity.json";

import config from "../../config";
import colors from "./../../resources/styles/colors";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    // Initialize state in the constructor
    this.state = {
      isChatWindowVisible: false,
      userPersona: false,
      context: null,
      countdown: 10, // Initial countdown value
      isTest: false,
      isLocal: false
    };

    // Bind methods to ensure 'this' context
    this.toggleChatWindow = this.toggleChatWindow.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    // this.renderCountdown = this.renderCountdown.bind(this);

    // Initialize interval ID to null
    this.countdownInterval = null;
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts to prevent memory leaks
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  startCountdown = () => {
    // this.setState({ countdown: 5 }); // Reset countdown to 5 seconds
    this.countdownInterval = setInterval(() => {
      if (this.state.countdown >= 0) {
        this.setState(prevState => ({ countdown: prevState.countdown - 1 }));
        if (this.state.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.setState({ isChatWindowVisible: true }); // Open chat window after countdown
        }
      }
      console.log("this.countdownInterval", this.countdownInterval);
    }, 1000); // Decrease countdown every second
  };

  getCleanDomain = () => {
    let domain = window.location.hostname;

    // Check if the domain contains 'localhost'
    if (
      domain.includes("localhost") ||
      domain.includes("172.20.102.131") ||
      domain.includes(config.testSite)
    ) {
      let url = window.location.href;
      // Create a URL object
      const urlObj = new URL(url);
      // Use URLSearchParams to get the query parameter named 'shop'
      const shopParam = urlObj.searchParams.get("shop");
      const isActive = urlObj.searchParams.get("isActive");
      const isLocal = urlObj.searchParams.get("isLocal");
      console.log("isLocal", isLocal)
      if (shopParam) {
        let shop = shopParam.endsWith("/") ? shopParam.slice(0, -1) : shopParam;

        const getCleanDomainFromURL = input => {
          let urlString = input;
          // Check if the input already includes a protocol; if not, prepend 'https://'
          if (!input.match(/^[a-zA-Z]+:\/\//)) {
            urlString = `https://${input}`;
          }

          try {
            const url = new URL(urlString);
            // Use a regular expression to remove any 'www.' prefix from the hostname
            const domain = url.hostname.replace(/^www\./, "");
            return domain; // Returns the domain name without www.
          } catch (error) {
            console.error("Invalid URL");
            return null;
          }
        };

        let websiteDomain = getCleanDomainFromURL(shop);
        if (isActive) {
          domain = `${websiteDomain}`;
        } else {
          domain = `tmp_${websiteDomain}`;
        }
        // this.setState({
        //   isChatWindowVisible: true
        // });
      } else {
        domain = "mod-ai-stylist.myshopify.com";
        // return "mod-ai-stylist.myshopify.com";
      }

      console.log("domain", domain);

      this.setState({ isTest: true, isLocal: isLocal });
      return domain;
    }

    if (domain.startsWith("www.")) {
      domain = domain.substring(4);
    }
    console.log("domain", domain);

    return domain;
  };

  getContext = async () => {
    const lambdaUrl = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/mod-context`;
    // Make a GET request to the Lambda function
    let response = await fetch(lambdaUrl);
    let data = await response.json();
    console.log("data context", data);
    console.log("this.state.userPersona", this.state.userPersona);
    let context = data.webChatContext;

    return context;

    // this.setState({
    //   systemMessage: {
    //     role: "system",
    //     content: context
    //   }
    // });
  };

  getUser = async () => {
    let domain = this.getCleanDomain();
    console.log("domain", domain);
    let shop = domain;
    let url = `${config.appServerUrl}?action=getUser&shop=${shop}`;
    console.log("shop", shop);
    console.log("get user url", url);

    let responseJson = await fetch(url);
    let response = await responseJson.json();
    let user = response.data;

    let context = await this.getContext();
    console.log("user from db", user);

    if (user.context && user.context.categories) {
      context = context.replace(
        "__CONTEXT_CATEGORIES_LIST__",
        user.context.categories
      );
      context = context.replace(
        "__CONTEXT_CATEGORIES_DESCRIPTION__",
        user.context.summary
      );
    }

    console.log("user from db", user);
    console.log("context app", context);

    if (this.state.isTest) {
      this.startCountdown();
    }

    this.setState({ userPersona: user, context: context });
  };

  componentDidMount() {
    this.getUser();
  }

  toggleChatWindow = () => {
    this.setState(prevState => ({
      isChatWindowVisible: !prevState.isChatWindowVisible
    }));
  };

  render() {
    console.log("this.state.userPersona", this.state.userPersona);
    return (
      <React.Fragment>
        {this.state.isTest && (
          <View>
            <View
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text mt={34} h5 regular_none_bold>
                  Test the experience
                </Text>
              </View>
              {!this.state.userPersona && (
                <View
                  style={{
                    // display: "flex",
                    flex: 1,
                    // overflowY: "auto",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <View
                    mt={12}
                    style={{
                      display: "flex",
                      // flex: 1,
                      // overflowY: "auto",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Lottie
                      animationData={loadingActivityIndicator}
                      loop={true}
                      style={{ width: 80, height: 80 }}
                    />
                    <Text> </Text>
                  </View>
                </View>
              )}

              {this.state.userPersona && (
                <React.Fragment>
                  <View
                    mt={24}
                    style={{
                      flex: 1,
                      // flexGrow: 1,
                      // backgroundColor: colors.bgVioletBase,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    <Image
                      source={{
                        uri:
                          "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_1024x1024.png" // this.state.userPersona.chat.imagePreviewUrl
                      }}
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 80,
                        backgroundColor: this.state.userPersona.chat
                          .primaryColor // colors.bgVioletBase
                        // borderColor: colors.bdLine,
                        // borderWidth: 1,
                      }}
                      resizeMode="contain"
                    />
                  </View>

                  <Text
                    mt={8}
                    regular_none_bold
                    mx={24}
                    style={{ textAlign: "center" }}
                  >
                    {this.state.userPersona.chat.name}
                  </Text>

                  <Text
                    mt={24}
                    regular_none_regular
                    style={{ textAlign: "center" }}
                  >
                    Connected to
                  </Text>

                  <View
                    mt={24}
                    style={{
                      flex: 1,
                      // flexGrow: 1,
                      // backgroundColor: colors.bgVioletBase,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    <Image
                      source={{
                        uri: this.state.userPersona.logo
                      }}
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 80,
                        // backgroundColor: this.state.userPersona.chat.primaryColor // colors.bgVioletBase
                        borderColor: colors.bdLine,
                        borderWidth: 1
                      }}
                      resizeMode="contain"
                    />
                  </View>

                  <Text
                    mt={8}
                    regular_none_bold
                    mx={24}
                    style={{ textAlign: "center" }}
                  >
                    {this.state.userPersona.shop.replace("tmp_", "")}
                  </Text>

                  {this.state.countdown > 0 && (
                    <View
                      style={{
                        // position: "absolute",
                        // bottom: 20,
                        // right: 20,
                        // zIndex: 100
                      }}
                    >
                      <Text
                        mt={24}
                        small_tight_regular
                        // numberOfLines={4}
                        ellipsizeMode="tail"
                        mx={32}
                        style={{
                          color: colors.inkLight,
                          textAlign: "center"
                        }}
                      >
                        {/*
                        Click on the AI stylist button from the footer, or the
                        chat will open in {this.state.countdown} seconds...
            */}
                        Opening chat in {this.state.countdown} seconds...
                      </Text>
                    </View>
                  )}

                  {/*
                <Text
                  mt={8}
                  small_tight_regular
                  mx={24}
                  style={{ textAlign: "center" }}
                >
                  AI Nutritionist
                </Text>
                */}

                  {/*
                <Text
                  mt={8}
                  small_tight_regular
                  numberOfLines={4}
                  ellipsizeMode="tail"
                  mx={32}
                  style={{ color: colors.inkLight, textAlign: "center" }}
                >
                  {this.state.userPersona.chat.description}
                </Text>
                */}
                </React.Fragment>
              )}
            </View>
          </View>
        )}

        {this.state.userPersona &&
          !!this.state.userPersona.chat &&
          !!this.state.userPersona.chat.enableChat && (
            <React.Fragment>
              {!this.state.isChatWindowVisible && (
                <FloatingChatButton
                  userPersona={this.state.userPersona}
                  onPress={this.toggleChatWindow}
                />
              )}
              <ChatWindow
                userPersona={this.state.userPersona}
                context={this.state.context}
                visible={this.state.isChatWindowVisible}
                onClose={this.toggleChatWindow}
                isTest={this.state.isTest}
                isLocal={this.state.isLocal}
              />
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}

export default App;
