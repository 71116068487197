import React, { Component } from "react";
import styled from "styled-components";
import colors from "./../../resources/styles/colors";
// Import your styled components
import {
  View,
  Text,
  TouchableOpacity
} from "./../../components/styled/styledComponents";

import ChatAI from "./../scan/ChatAI";

import imgClose from "./../../assets/close.png"; // Adjust the path as necessary
import imgBottom from "./../../assets/img_bottom_3.png"; // Adjust the path as necessary

class ChatWindow extends Component {
  state = {
    isHalfSize: true,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleDimensionsChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDimensionsChange);
  }

  handleDimensionsChange = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  };

  toggleSize = () => {
    this.setState(prevState => ({
      isHalfSize: !prevState.isHalfSize
    }));
  };

  render() {
    if (!this.props.visible) {
      return null;
    }

    const { windowHeight, windowWidth, isHalfSize } = this.state;
    const halfHeight = Math.max(windowHeight / 1.5, 250);
    const isMobile = windowWidth < 481;
    const marginSize = isMobile ? 20 : 20;
    const chatWidth = isMobile ? windowWidth - marginSize * 2 : 400;
    const chatHeight = Math.min(windowHeight - 40, 650);

    return (
      <View
        style={{
          ...styles.container,
          height: chatHeight,
          right: marginSize,
          ...(isMobile
            ? { left: marginSize, width: chatWidth }
            : { width: chatWidth })
        }}
      >
        <ChatAI
          chatWidth={chatWidth}
          userPersona={this.props.userPersona}
          context={this.props.context}
          isTest={this.props.isTest}
          isLocal={this.props.isLocal}
          header={
            <View
              style={{
                // width: "100%",
                paddingVertical: 8,
                paddingHorizontal: 16,
                backgroundColor: "#FFFFFF",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderTopLeftRadius: 10, // Match the container's borderRadius
                borderTopRightRadius: 10,
                borderBottom: `0.1px solid ${colors.inkDark}`,
                // borderColor: colors.bdLine,
                backgroundColor: this.props.userPersona.chat.primaryColor // colors.bgVioletBase
              }}
            >
              <View
                mx={24}
                my={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <View my={8}>
                  <Text mb={4} regular_none_bold style={{ color: "#fff" }}>
                    {/* Runa */}
                    {this.props.userPersona.chat.name}
                  </Text>
                  {/*
                  <Text small_tight_regular style={{ color: "#fff" }}>
                    AI Nutritionist
                  </Text>
                  */}
                </View>
                <img
                  onClick={this.props.onClose}
                  src={imgBottom}
                  alt="Close"
                  style={{
                    width: 20,
                    height: 20,
                    cursor: "pointer"
                    // padding: 2
                  }}
                />
              </View>
            </View>
          }
        />
      </View>
    );
  }
}

// Define your styles here
const styles = {
  container: {
    // justifyContent: 'flex-end',
    // alignItems: "center",
    // minWidth: 350,
    // border: `1px solid ${colors.bdLine}`,
    borderWidth: 1,
    borderColor: colors.bdLine,
    backgroundColor: "#fff",
    borderRadius: 10,
    // overflow: "hidden",
    // overflowY: "auto", // Enable vertical scrolling
    position: "fixed",
    // height: "100%",
    bottom: 20, // Align to the bottom
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 5px 40px"
  },
  header: {
    // // width: "100%",
    // paddingVertical: 8,
    // paddingHorizontal: 16,
    // backgroundColor: "#FFFFFF",
    // flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "center",
    // borderTopLeftRadius: 10, // Match the container's borderRadius
    // borderTopRightRadius: 10,
    // borderBottom: `0.1px solid ${colors.inkDark}`,
    // // borderColor: colors.bdLine,
    // // backgroundColor: colors.bgVioletBase
  },
  headerText: {
    fontWeight: "bold"
  },
  closeButton: {
    padding: 8
  },
  closeButtonText: {
    fontWeight: "bold",
    fontSize: 18
  },
  sizeButton: {
    padding: 8
  },
  sizeButtonText: {
    fontWeight: "bold",
    fontSize: 18
  }
  // overlay: {
  //   position: "absolute",
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   backgroundColor: "rgba(0, 0, 0, 0.5)"
  // }
  // Define other styles...
};

export default ChatWindow;
