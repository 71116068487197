import React, { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
// import { SlArrowRightCircle } from "react-icons/sl";
// import { SlArrowRight } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import styled from "styled-components";
import "./Products.css";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";

import colors from "./../../resources/styles/colors";
import loaders from "./../../utils/personas_loaders";

import config from "../../config";

// Define the ArrowButton using styled-components
const ArrowButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2px;
  opacity: ${props => (props.disabled ? "0" : "1")};
  user-select: none;
  border-radius: 6px;
  border-width: 1px;
  background: "transparent";
`;

// Arrow function component
function Arrow({ children, disabled, onClick, className, testId }) {
  return (
    <ArrowButton
      disabled={disabled}
      onClick={onClick}
      className={`arrow-${className}`}
      data-testid={testId}
    >
      {children}
    </ArrowButton>
  );
}

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function App(props) {
  // console.log("props 1", props);
  const [items, setItems] = React.useState(props.items || []);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  useEffect(
    () => {
      setItems(props.items);
    },
    [props.items]
  ); // Re-run the effect only if props.items changes

  const isItemSelected = id => !!selected.find(el => el === id);

  const handleClick = id => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id);

    setSelected(
      currentSelected =>
        itemSelected
          ? currentSelected.filter(el => el !== id)
          : currentSelected.concat(id)
    );
  };

  const apiRef = React.useRef({});

  const myScrollNext = () => {
    console.log(apiRef.current);
    console.log(apiRef.current.getNextItem());
    let nextItem = apiRef.current.getNextItem();
    let totalItems = items.length;
    console.log(totalItems);
    if (nextItem) {
      let id = nextItem.key;
      let tmpId = parseInt(id) + 2;
      let newId = tmpId < totalItems - 1 ? tmpId : totalItems - 1;
      console.log("newId", newId);
      apiRef.current.scrollToItem(
        apiRef.current.getItemById(`${newId}`)
        // OR if you not sure about id for first item
        // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
        // "auto",
        // "start"
      );
    }
  };

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(
      VisibilityContext
    );

    return (
      <div style={{ width: 0, height: 0 }} onClick={() => scrollPrev()}>
        <div
          style={{
            position: "relative",
            left: 0,
            top: (144 * 1.1 - 50) / 2,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isFirstItemVisible && <div />}
          {!isFirstItemVisible && (
            <View
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
                // backgroundColor:"#fff",
                // backgroundImage: "linear-gradient(to left,rgb(255 255 255/0),white var(--f-fw-z-a-i))"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // margin: 5,
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowBack size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(
      VisibilityContext
    );

    return (
      <div
        style={{ width: 0, height: 0 }}
        onClick={() => {
          console.log("scrollNext", scrollNext);
          // scrollNext();
          myScrollNext();
        }}
      >
        <div
          style={{
            position: "relative",
            left: -50,
            top: (144 * 1.1 - 50) / 2,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isLastItemVisible && <div />}
          {!isLastItemVisible && (
            <View
              style={{
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  // flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowForward size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }
  function Card({ onClick, selected, title, itemId, item, keyNr }) {
    const visibility = React.useContext(VisibilityContext);
    let product = item;

    return (
      <View
        mx={4}
        // mb={8}
        ml={keyNr == 0 ? 58 : 5}
        onClick={() => onClick(visibility)}
        style={{
          // width: "160px",
          // marginHorizontal: "5px"
        }}
        tabIndex={0}
      >
        <div className="card">
          <View key={itemId}>
            <TouchableOpacity
              onClick={() => {
                let domain = window.location.hostname;
                let url;
                if (product.metadata.url.includes("https://")) {
                  url = product.metadata.url;
                } else {
                  url = `https://${props.shop}/products/${product.metadata
                    .url}`;
                }

                if (
                  domain.includes("localhost") ||
                  domain.includes("172.20.102.131")
                ) {
                  window.open(
                    url,
                    // product.image.contextLink,
                    "_blank"
                  );
                } else {
                  window.location.href = url;
                }

                // window.location.href =
                //   product.image.contextLink;

                config.analytics.trackProduction(`Product Link`, {
                  distinct_id: config.userId,
                  product_title: product.title,
                  product_link: product.image ? product.image.contextLink : ""
                });
                // this.props.navigation.push("ProductWebView", {
                //   uri: product.image.contextLink,
                //   title: product.displayLink
                // });
                // WebBrowser.openBrowserAsync(
                //   product.image.contextLink
                // );
              }}
            >
              <View
                // mx={10}
                style={{
                  alignItems: "center",
                  paddingHorizontal: 20
                }}
              >
                <Image
                  // transition={500}
                  // width={96 * 1.7}
                  // height={144 * 1.7}
                  // placeholder={
                  //   config.blurhash_2x3
                  // }
                  // source={{ uri: product.image.thumbnailLink }}
                  source={{
                    uri: `${product.metadata.image}`
                  }}
                  // source={{
                  //   uri: `https://traveline-images.s3.amazonaws.com/fashionapp/general/${this
                  //     .state.persona.image}`
                  // }}
                  // uri={imgUrl}
                  // preview={preview}
                  //indicator={ProgressBar}
                  style={{
                    width: 96 * 1.1,
                    height: 144 * 1.1,
                    // width: 96, // (width - 60) / 2, //width * 0.35,
                    // height: 144, // (width - 60) / 2 * 1.5,
                    // marginTop: 20,
                    // textAlign: 'center',
                    // backgroundColor: 'blue',
                    //marginLeft: parseInt((width - imgWidth) / 2),
                    // marginTop: getStatusBarHeight(true) + 30,
                    //marginTop: isNativeApp ? getStatusBarHeight(true) + 30 : 80,
                    //marginBottom: 40,
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: colors.inkLighter
                  }}
                  // resizeMode="contain"
                  // maxBytes="100000"
                  // contentFit="contain"
                />
              </View>
            </TouchableOpacity>
          </View>
        </div>
      </View>
    );
  }

  return (
    <View>
      {items &&
        items.length == 0 && (
          <View
            style={{
              width: "100%",
              height: 144 * 1.1 + 6,
              display: "flex",
              // alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text
              tiny_none_regular
              px={12}
              py={8}
              // style={{
              //   marginLeft: 20,
              //   paddingLeft: 40
              // }}
            >
              No products found.
            </Text>
          </View>
        )}
      {items &&
        items.length > 0 && (
          <ScrollMenu
            apiRef={apiRef}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            // style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {items.map((item, keyNr) => (
              <Card
                itemId={keyNr} // NOTE: itemId is required for track items
                title={item.id}
                key={item.id}
                onClick={handleClick(item.id)}
                selected={isItemSelected(item.id)}
                item={item}
                keyNr={keyNr}
              />
            ))}
          </ScrollMenu>
        )}
    </View>
  );
}

// <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//   Left
// </Arrow>

export default App;

//
// import React from "react";
// import ReactDOM from "react-dom";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import 'react-horizontal-scrolling-menu/dist/styles.css';
//
// import { LeftArrow, RightArrow } from "./arrows";
// import { Card } from "./card";
// import { Footer } from "./footer";
// import { Header } from "./header";
// import "./globalStyles.css";
//
// // NOTE: embrace power of CSS flexbox!
// // import "./hideScrollbar.css";
// // import "./firstItemMargin.css";
//
// type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
//
// const elemPrefix = "test";
// const getId = (index: number) => `${elemPrefix}${index}`;
//
// const getItems = () =>
//   Array(20)
//     .fill(0)
//     .map((_, ind) => ({ id: getId(ind) }));
//
// function App() {
//   const [items] = React.useState(getItems);
//
//   // NOTE: apiRef
//   const apiRef = React.useRef({} as scrollVisibilityApiType);
//
//   const reset = () => {
//     apiRef.current.scrollToItem(
//       apiRef.current.getItemById("test0"),
//       // OR if you not sure about id for first item
//       // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
//       "auto",
//       "start"
//     );
//   };
//   const ResetButton = ({ onClick }: { onClick: VoidFunction }) => (
//     <button id="reset" onClick={onClick} style={{ marginTop: "50px" }}>
//       RESET
//     </button>
//   );
//
//   return (
//     <>
//       <Header />
//       <div className="example" style={{ paddingTop: "100px" }}>
//         <div>
//           <ScrollMenu
//             apiRef={apiRef}
//             LeftArrow={LeftArrow}
//             RightArrow={RightArrow}
//             onWheel={onWheel}
//           >
//             {items.map(({ id }) => (
//               <Card
//                 title={id}
//                 itemId={id} // NOTE: itemId is required for track items
//                 key={id}
//               />
//             ))}
//           </ScrollMenu>
//           <ResetButton onClick={reset} />
//         </div>
//         <Footer />
//       </div>
//     </>
//   );
// }
// export default App;
//
// function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
//
//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }
//
//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }
//
// ReactDOM.render(<App />, document.getElementById("root"));
